<route>
{
  "meta": {
    "noTransition": true
  }
}
</route>

<template>
  <com-layout class="page">
    <el-card v-loading="loading">
      <el-form :rules="cacheFormRule" :model="cache" ref="cacheFormDetail" label-width="10em">
        <el-form-item label="服务说明模板">
          <el-button-group>
            <el-button @click="pickNote(0)" plain type="warning" size="mini">清空</el-button>
            <el-button @click="pickNote(1)" plain type="success" size="mini">模板（广州）</el-button>
            <el-button @click="pickNote(2)" plain type="success" size="mini">模板（番禺）</el-button>
            <el-button @click="pickNote(3)" plain type="success" size="mini">模板（深圳）</el-button>
            <el-button @click="pickNote(4)" plain type="success" size="mini">模板（佛山）</el-button>
            <el-button @click="pickNote(5)" plain type="success" size="mini">模板（东莞）</el-button>
            <el-button @click="pickNote(6)" plain type="success" size="mini">模板（上海）</el-button>
            <el-button @click="pickNote(7)" plain type="success" size="mini">模板（北京）</el-button>
            <el-button @click="pickNote(8)" plain type="success" size="mini">模板（顺德）</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item v-if="isShowCommodityPackage && !isCopy" label="套餐详情">
          <com-packageData></com-packageData>
        </el-form-item>
        <el-form-item label="购买须知" prop="purchaseInstructions">
          <bm-tinymce
            :value="cache.purchaseInstructions"
            @input="updateCache({ key: 'purchaseInstructions', value: $event })"
            :height="300"
          ></bm-tinymce>
        </el-form-item>
        <el-form-item label="商品详情" prop="productDetailContent">
          <bm-tinymce
            :value="cache.productDetailContent"
            @input="updateCache({ key: 'productDetailContent', value: $event })"
            :height="300"
            :xiumi="true"
          ></bm-tinymce>
        </el-form-item>

        <template v-if="saleType === 2">
          <el-form-item label="使用提示" prop="useTips">
            <el-input
              :value="cache.useTips"
              @input="updateCache({ key: 'useTips', value: $event })"
              maxlength="35"
              placeholder="请输入使用提示"
              style="width:500px"
            ></el-input>
            <bm-tip>该提示会在电子凭证页展示</bm-tip>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button @click="handleNext" type="primary">下一步</el-button>
          <el-button v-if="!isAdd" @click="handleSubmit" type="success">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </com-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import editMixin from './__com__/editMixin'
import comPackageData from './__com__/packageData'

export default {
  components: { comPackageData },
  data: () => ({
    foo: 'bar'
  }),
  mixins: [editMixin],
  computed: {
    ...mapGetters('product/partner', ['isShowCommodityPackage', 'saleType']),
    isCopy() {
      return Boolean(this.$route.query.copy)
    }
  },
  methods: {
    ...mapMutations('product/partner', ['updateCache', 'pickNote']),
    ...mapActions({}),
    handleNext() {
      this.$refs.cacheFormDetail.validate(valid => {
        if (valid) {
          let query = {}
          if (this.$route.query.copy) {
            query.copy = 1
          }
          this.$router.push({
            path: `/product/partnerProduct/${this.$route.params.id}/extend?id=${this.$route.query.id || ''}`,
            query
          })
        } else {
          this.$message.error('表单信息不完整')
          return false
        }
      })
    }
  },
  created() {},
  mounted() {
    this.loading = true
    Promise.all([this.getData()]).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="less" scoped>
.packageHtmlContainer {
  width: 100%;
}
</style>
