<template>
  <div class="packageDataContainer">
    <div class="title">套餐详情</div>
    <div class="packageList">
      <div class="nameColumn">
        <div class="item header">名称</div>
        <div v-for="(i, n) in listOrdered" :key="n" class="item">
          {{ i.name }}
        </div>
      </div>
      <div class="packageColumn">
        <div class="item header">数量</div>
        <div v-for="(i, n) in packageList" :key="n" class="item" :class="{ ['count-' + i.count]: true }">
          {{ i.package }}
        </div>
      </div>
      <div class="priceColumn">
        <div class="item header">价格</div>
        <div v-for="(i, n) in listOrdered" :key="n" class="item">
          {{ i.price }}
        </div>
      </div>
    </div>
    <div class="priceContainer">
      <div class="item">
        <div class="header">原价</div>
        <div class="value">￥{{ originalPrice | formatPrice }}</div>
      </div>
      <div class="item tip_item">
        <div class="header">贪吃价</div>
        <div class="value">￥{{ salePrice | formatPrice }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({}),
  computed: {
    ...mapGetters('product/partner', ['cache', 'commodityData']),
    originalPrice() {
      if (this.cache.skuList && this.cache.skuList.length) {
        return this.cache.skuList[0].originalPrice
      } else {
        return 0
      }
    },
    salePrice() {
      if (this.cache.skuList && this.cache.skuList.length) {
        return this.cache.skuList[0].salePrice
      } else {
        return 0
      }
    },
    listOrdered() {
      return this.commodityData.package
        .map((i, n) => {
          i.id = n
          return i
        })
        .sort((a, b) => {
          return a.packageId.localeCompare(b.packageId)
        })
    },
    packageList() {
      let result = []
      this.listOrdered.forEach(i => {
        if (!i.packageId) {
          result.push({ id: i.id, package: i.package, packageId: '', count: 1 })
        } else {
          const n = result.findIndex(r => r.packageId === i.packageId)
          if (n >= 0) {
            result[n].count++
          } else {
            result.push({ id: i.id, package: i.package, packageId: i.packageId, count: 1 })
          }
        }
      })
      return result
    }
  },
  watch: {},
  methods: {
    ...mapMutations('product/partner', ['updateCache']),
    ...mapActions({}),
    handleT() {
      this.updateCache({ key: 'purchaseInstructions', value: this.bar })
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
@h: 45px;
.bordered-none {
  border-left: none;
  border-right: none;
}

.packageDataContainer {
  max-width: 600px;
  .title {
    text-align: center;
  }
}
.title {
  height: @h;
  line-height: @h;
  background: rgba(252, 193, 6, 1);
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;
}
.packageList {
  display: flex;
  font-size: 12px;
  border: 1px solid #ccc;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  .nameColumn,
  .packageColumn,
  .priceColumn {
    flex-basis: 33%;
    flex-shrink: 0;
    text-align: center;
    .item {
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      line-height: 15px;
      overflow: hidden;
      border: 1px solid #ccc;
      border-top: none;
      height: @h;
      // line-height: @h;
      &:last-child {
        border-bottom: none;
      }
      &.header {
        color: #000;
      }
    }
  }
  .nameColumn {
    flex-grow: 1;
  }
  .packageColumn {
    flex-basis: 10em;
    flex-grow: 0;
    .item {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      line-height: 15px;
      overflow: hidden;
      &.count-1 {
        .bordered-none();
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
      }
      &.count-2 {
        .bordered-none();
        height: @h * 2;
      }
      &.count-3 {
        .bordered-none();
        height: @h * 3;
      }
      &.count-4 {
        .bordered-none();
        height: @h * 4;
      }
      &.count-5 {
        .bordered-none();
        height: @h * 5;
      }
      &.count-6 {
        .bordered-none();
        height: @h * 6;
      }
      &.count-7 {
        .bordered-none();
        height: @h * 7;
      }
      &.count-8 {
        .bordered-none();
        height: @h * 8;
      }
      &.count-9 {
        .bordered-none();
        height: @h * 9;
      }
      &.header {
        border-left: none;
        border-right: none;
      }
    }
  }
  .priceColumn {
    flex-basis: 6em;
    flex-grow: 0;
  }
}
.priceContainer {
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  .item {
    color: #000;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .header {
      flex-grow: 1;
      text-align: center;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
    .value {
      flex-basis: calc(5em + 1px);
      text-align: center;
      border-right: 1px solid #ccc;
    }
    &.tip_item {
      color: rgba(192, 0, 0, 1);
      border: none;
    }
  }
}
</style>
